export default {
  TWITTER_AT: '@Wizards',
  GIT_COMMIT: 'c46a64d6f83a31968f56685f58a5be040199049c',
  CI_JOB_ID: '8336629297',
  LOCATOR_VERSION: 'local_version',
  EVENT_RESERVATION_GRAPHQL_SERVICE: 'https://api.tabletop.wizards.com/silverbeak-griffin-service/graphql',
  GOOGLE_ANALYTICS_ID: 'UA-15020098-23',
  GOOGLE_TAG_MANAGER_ID: 'GTM-5QMQ67K',
  WOTC_ANALYTICS_URL: 'https://sel.api.bi.wizards.com/'

}
